import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface FarmPanelProps {
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  }
}));

const FarmPanel = ({ children, className }: FarmPanelProps) => {
  const classes = clsx(useStyles().root, className);

  return <Paper className={classes}>{children}</Paper>;
};

export default FarmPanel;

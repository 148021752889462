import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Paper, PaperProps } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    outline: 'none',
    position: 'relative'
  },
  close: {
    top: 5,
    right: 5,
    position: 'absolute',
    zIndex: 10
  }
});

interface ModalPaperProps extends PaperProps {
  onClose?: () => void;
}

const ModalPaper = React.forwardRef(
  ({ children, className, onClose, ...rest }: ModalPaperProps, ref) => {
    const classes = useStyles();
    const classNames = clsx(classes.root, className);

    return (
      <Paper ref={ref} className={classNames} {...rest}>
        <>
          <IconButton
            onClick={onClose}
            className={classes.close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {children}
        </>
      </Paper>
    );
  }
);

ModalPaper.displayName = 'ModalPaper';

export default ModalPaper;

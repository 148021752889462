import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { makeStyles } from '@material-ui/core/styles';
import efame from '../../../img/efame.png';

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

interface ItemFarmStatisticsProps {
  stakedNftsCount?: number;
  reward?: number;
}

const ItemFarmStatistics = ({
  stakedNftsCount,
  reward
}: ItemFarmStatisticsProps) => {
  return (
    <List className={useStyles().root}>
      <ListItem>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText
          primary={stakedNftsCount ?? 'loading...'}
          secondary="Staked NFTs"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <img height={24} width={24} src={efame} alt="" />
        </ListItemIcon>
        <ListItemText primary={reward ?? 'loading...'} secondary="Reward" />
      </ListItem>
    </List>
  );
};

export default ItemFarmStatistics;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  img: {
    maxWidth: '100%',
    maxHeight: '100%'
  }
});

interface InventoryItemDetailsModalImageProps {
  imgSrc?: string;
}

const InventoryItemDetailsModalImage = ({
  imgSrc
}: InventoryItemDetailsModalImageProps) => {
  return (
    <Grid item container xs={12} sm={4} justify="center" alignItems="center">
      <img className={useStyles().img} src={imgSrc} alt="" />
    </Grid>
  );
};

export default InventoryItemDetailsModalImage;

import React, { useState } from 'react';
import { TabPanel, TabPanelProps } from '@material-ui/lab';
import Erc20FarmStatistics from './Erc20FarmStatistics';
import { Button, Grid } from '@material-ui/core';
import { useStatistics } from './StatisticsContext';
import { useContracts } from '../../../ContractContext';
import { Contract } from 'ethers';

interface Erc20FarmModalWithdrawTabProps extends TabPanelProps {
  erc20: Contract;
}

const Erc20FarmModalWithdrawTab = ({
  erc20,
  ...rest
}: Erc20FarmModalWithdrawTabProps) => {
  const { farm } = useContracts();
  const { staked, update } = useStatistics();

  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const unstake = async () => {
    const tx = await farm?.unstakeERC20(erc20.address);
    await tx.wait();
  };

  const claimReward = async () => {
    const tx = await farm?.payoutRewardERC20(erc20.address);
    await tx.wait();
  };

  const handleWithdrawClick = async () => {
    setButtonsEnabled(false);
    await unstake();
    await update?.();
    setButtonsEnabled(true);
  };

  const handleClaimClick = async () => {
    setButtonsEnabled(false);
    await claimReward();
    await update?.();
    setButtonsEnabled(true);
  };

  return (
    <TabPanel {...rest}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Erc20FarmStatistics />
        </Grid>
        <Grid item xs>
          <Button
            disabled={!staked || staked === 0 || !buttonsEnabled}
            onClick={handleWithdrawClick}
            fullWidth
            variant="contained"
            color="primary"
          >
            Withdraw
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            disabled={!staked || staked === 0 || !buttonsEnabled}
            onClick={handleClaimClick}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Claim
          </Button>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default Erc20FarmModalWithdrawTab;

import React from 'react';
import ItemFarmModalInventoryItem from '../itemFarm/ItemFarmModalInventoryItem';

interface T1InventoryItemProps {
  imgSrc: string;
  name: string;
  fame: number;
  power: number;
  booster: boolean;
  extra?: string;
  action: React.ReactNode;
}

const T1FarmModalInventoryItem = ({
  imgSrc,
  name,
  fame,
  power,
  booster,
  extra,
  action
}: T1InventoryItemProps) => {
  let secondaryText = `Power: ${power} Fame: ${fame}`;
  if (typeof booster !== 'undefined') {
    secondaryText += ` Booster: ${booster ? 'Yes' : 'No'}`;
  }

  return (
    <ItemFarmModalInventoryItem
      imgSrc={imgSrc}
      primaryText={name}
      secondaryText={secondaryText}
      extra={extra}
      action={action}
    />
  );
};

export default T1FarmModalInventoryItem;

import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { makeStyles } from '@material-ui/core/styles';
import { useStatistics } from './StatisticsContext';
import efame from '../../../img/efame.png';

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

const Erc20FarmStatistics = () => {
  const { staked, reward } = useStatistics();

  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <AccountBalanceWalletIcon />
        </ListItemIcon>
        <ListItemText primary={staked ?? 'loading...'} secondary="Staked" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <img height={24} width={24} src={efame} alt="" />
        </ListItemIcon>
        <ListItemText primary={reward ?? 'loading...'} secondary="Reward" />
      </ListItem>
    </List>
  );
};

export default Erc20FarmStatistics;

import React, { useEffect, useState } from 'react';
import ItemFarmStatistics from '../itemFarm/ItemFarmStatistics';
import { Button } from '@material-ui/core';
import ItemFarmPanel from '../itemFarm/ItemFarmPanel';
import T1FarmModal from './T1FarmModal';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import useStakedT1Tokens from './useStakedT1Tokens';
import { BigNumber, Contract, utils } from 'ethers';
import useOnBlock from '../../../utils/useOnBlock';

interface T1FarmPanelProps {
  t1Contract: Contract;
  className?: string;
  title?: string;
}

const T1FarmPanel = ({ t1Contract, className, title }: T1FarmPanelProps) => {
  const { active } = useWeb3React<Web3Provider>();
  const {
    indices,
    refreshStakedBalance,
    getStakedTokenValue
  } = useStakedT1Tokens(t1Contract);
  const [totalValue, setTotalValue] = useState<number>();

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!indices) {
      return;
    }

    const update = async () => {
      let total = 0;
      for (const index of indices) {
        const val: BigNumber = await getStakedTokenValue(index.toNumber());
        total += +utils.formatEther(val);
      }

      setTotalValue(total);
    };

    update().catch(() => refreshStakedBalance());
  }, [indices]);

  useOnBlock(() => refreshStakedBalance());

  const toggleModal = async () => {
    setModalOpen((v) => !v);
    await refreshStakedBalance();
  };

  return (
    <ItemFarmPanel className={className} title={title}>
      <T1FarmModal egg={t1Contract} open={modalOpen} onClose={toggleModal} />
      <ItemFarmStatistics
        stakedNftsCount={indices?.length}
        reward={totalValue}
      />
      <Button disabled={!active} onClick={toggleModal} variant="contained">
        Manage
      </Button>
    </ItemFarmPanel>
  );
};

export default T1FarmPanel;

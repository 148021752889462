import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import ActionButton from '../../components/ActionButton';
import { Contract } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import config from '../../utils/config';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1)
  }
}));

interface InventoryItemDetailsModalTransferProps {
  id?: number;
  eggContract?: Contract;
  onTransfer?: () => void;
}

const InventoryItemDetailsModalTransfer = ({
  id,
  eggContract,
  onTransfer
}: InventoryItemDetailsModalTransferProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { account } = useWeb3React<Web3Provider>();

  const [isOwned, setIsOwned] = useState<boolean>(true);
  const [newAddress, setNewAddress] = useState<string>();

  useEffect(() => {
    if (id) {
      eggContract
        ?.ownerOf(id)
        .then((owner: string) => setIsOwned(owner === account));
    }
  }, [id, eggContract]);

  const handleTransferClick = async () => {
    if (newAddress) {
      const tx = await eggContract?.transferFrom(account, newAddress, id, {
        gasLimit: config.gasLimits.transfer
      });

      enqueueSnackbar(`Transferring token ${id}...`, {
        autoHideDuration: 5000
      });
      onTransfer?.();

      await tx.wait();
      enqueueSnackbar(`Token ${id} successfully transferred`, {
        variant: 'success'
      });
    }
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAddress(e.target.value);
  };

  let transferButtonText = 'Transfer';
  if (!isOwned) {
    transferButtonText =
      'The token was transferred out and will be removed from your inventory shortly.';
  }

  return (
    <form>
      <TextField
        onChange={handleAddressChange}
        fullWidth
        className={useStyles().textField}
        label="Transfer to address..."
      />
      <Button
        onClick={handleTransferClick}
        fullWidth
        disabled={!isOwned}
        variant="contained"
        color="primary"
      >
        {transferButtonText}
      </Button>
    </form>
  );
};

export default InventoryItemDetailsModalTransfer;

import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import Erc20FarmPanel from '../features/farm/erc20Farm/Erc20FarmPanel';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useContracts } from '../ContractContext';
import T1FarmPanel from '../features/farm/t1Farm/T1FarmPanel';
import Erc721FarmPanel from '../features/farm/erc721Farm/Erc721FarmPanel';
import config from '../utils/config';
import { BigNumber } from 'ethers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1
  },
  panel: {
    marginBottom: theme.spacing(2)
  }
}));

const FarmTabPanel = () => {
  const [supply, setSupply] = useState<number>();
  const { eggs, erc20s, erc721s } = useContracts();

  useEffect(() => {
    eggs?.[0]
      ?.totalSupply()
      .then((val: BigNumber) => setSupply(val.toNumber()));
  }, [eggs]);

  const classes = useStyles();

  return (
    <Box p={3} className={classes.root}>
      {/* <Typography variant="h5" component="h1" align="center">
        {`Farm start on Token #2000 - Currently ${supply ?? '##'} minted.`}
      </Typography>*/}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {eggs?.map((egg, i) => (
            <T1FarmPanel
              className={classes.panel}
              key={i}
              t1Contract={egg}
              title={`NFTeGG Farm ${i + 1}`}
            />
          ))}
		  {/*

          {erc721s?.map((erc721, i) => (
            <Erc721FarmPanel
              className={classes.panel}
              key={i}
              erc721Contract={erc721}
              title={config.contracts.erc721.farms[i].name}
            />
          ))}
		   */}

        </Grid>
        <Grid item xs={6}>
          {erc20s?.map((erc20, i) => (
            <Erc20FarmPanel
              className={classes.panel}
              key={i}
              erc20Contract={erc20}
              title={`eFAME/BNB LP Farm ${i + 1}`}
            />
          ))}
        </Grid>
      </Grid>
	                            <Typography variant="h5" component="h1" align="center">
                                <br/>Please read our new Medium Post about our move.<br/>
                                <a href="https://nftegg.medium.com/nftegg-and-bsc-in-2021-f2c7249d4224">Link Medium</a>
                                </Typography>

    </Box>
  );
};

export default FarmTabPanel;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import info3 from '../img/info3.png';
import AppTabPanel from './AppTabPanel';

const useStyles = makeStyles({
  imgContainer: {
    textAlign: 'center'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%'
  }
});

const HomeTabPanel = () => {
  const classes = useStyles();

  return (
    <AppTabPanel>
      <div className={classes.imgContainer}>
        <img className={classes.img} src={info3} alt="" />
      </div>
    </AppTabPanel>
  );
};

export default HomeTabPanel;

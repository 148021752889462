import React, { useEffect, useState } from 'react';
import { Chip, Grid, Link, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faMedium,
  faTelegram,
  faDiscord
} from '@fortawesome/free-brands-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: 'flex'
  },
  icons: {
    marginLeft: 'auto'
  },
  anchor: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  chip: {
    flex: '1 0 0',
    margin: `0 ${theme.spacing(2)}px`,
    width: 0
  }
}));

const links = [
  {
    icon: faBook,
    link: 'https://docs.nftegg.com/'
  },
  {
    icon: faTwitter,
    link: 'https://twitter.com/nftegg'
  },
  {
    icon: faMedium,
    link: 'https://nftegg.medium.com'
  },
  {
    icon: faTelegram,
    link: 'https://t.me/nftegg'
  },
  {
    icon: faDiscord,
    link: 'https://discord.gg/DBjknZE'
  }
];

const Footer = () => {
  const [refWallet, setRefWallet] = useState(localStorage.getItem('refWallet'));

  const handleDelete = () => {
    localStorage.removeItem('refWallet');
    setRefWallet(null);
  };

  useEffect(() => {
    setRefWallet(localStorage.getItem('refWallet'));
  });

  const classes = useStyles();

  return (
    <footer>
      <div className={classes.paper}>
        <div>
          <Typography variant="subtitle2" component="p">
            &copy; NFTeGG 2020
          </Typography>
        </div>
        {refWallet && (
          <Chip
            className={classes.chip}
            variant="outlined"
            label={`Referral: ${refWallet}`}
            onDelete={handleDelete}
          />
        )}
        <div className={classes.icons}>
          <Grid container spacing={2}>
            {links.map((link, key) => (
              <Grid item key={key}>
                <Link
                  className={classes.anchor}
                  color="inherit"
                  href={link.link}
                >
                  <FontAwesomeIcon icon={link.icon} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { Contract } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

export default (to?: string, tokenContract?: Contract) => {
  const { account } = useWeb3React<Web3Provider>();

  const [isApproved, setIsApproved] = useState<boolean>();

  const getApprovalStatus = useCallback(async () => {
    if (!to || !tokenContract) return undefined;

    return (await tokenContract?.isApprovedForAll(account, to)) as Promise<
      boolean
    >;
  }, [tokenContract, account, to]);

  useEffect(() => {
    getApprovalStatus().then((a) => setIsApproved(a));
  }, [getApprovalStatus]);

  const setApprovalIfRequired = useCallback(async () => {
    const hasApproval = await getApprovalStatus();

    if (hasApproval || hasApproval === undefined) return;

    const tx = await tokenContract?.setApprovalForAll(to, true);
    await tx.wait();
  }, [getApprovalStatus, tokenContract, to]);

  return {
    isApproved,
    getApprovalStatus,
    setApprovalIfRequired
  };
};

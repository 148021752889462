import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useEffect } from 'react';

export default (listener: () => void) => {
  const { library } = useWeb3React<Web3Provider>();
  useEffect(() => {
    library?.on('block', listener);
    return () => {
      library?.off('block', listener);
    };
  }, [library, listener]);
};

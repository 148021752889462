import React from 'react';
import { ModalProps } from '@material-ui/core';
import CenteringModal from '../../../components/CenteringModal';
import ModalPaper from '../../../components/ModalPaper';
import { makeStyles } from '@material-ui/core/styles';

interface ItemFarmModalProps extends Pick<ModalProps, 'open'> {
  onClose: () => void;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    minWidth: 500,
    maxWidth: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  }
}));

const ItemFarmModal = ({ open, children, onClose }: ItemFarmModalProps) => {
  const classes = useStyles();

  return (
    <CenteringModal open={open} onClose={onClose}>
      <ModalPaper className={classes.modalPaper} onClose={onClose}>
        {children}
      </ModalPaper>
    </CenteringModal>
  );
};

export default ItemFarmModal;

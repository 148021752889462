import React from 'react';
import { Box } from '@material-ui/core';

interface Props {
  className?: string;
}

const AppTabPanel = ({
  children,
  className
}: React.PropsWithChildren<Props>) => {
  return (
    <Box p={3} className={className}>
      {children}
    </Box>
  );
};

export default AppTabPanel;

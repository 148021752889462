import React, { useCallback, useEffect, useState } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Contract, ContractInterface, ethers, Signer } from 'ethers';
import config from './utils/config';

interface ContractContextValue {
  eggs?: Contract[];
  ticket?: Contract;
  farm?: Contract;
  ref?: Contract;
  efame?: Contract;
  erc20s?: Contract[];
  erc721s?: Contract[];
  connect?: () => void;
}

export const ContractContext = React.createContext<ContractContextValue>({});

export const useContracts = () => React.useContext(ContractContext);

const injected = new InjectedConnector({ supportedChainIds: [config.chainId] });

const createContract = (
  address: string,
  abi: ContractInterface,
  signer: Signer
): Contract => {
  const contract = new ethers.Contract(address, abi, signer);
  contract.connect(signer);

  return contract;
};

interface ContractContextProviderProps {
  children: React.ReactNode;
}

const ContractProvider = ({ children }: ContractContextProviderProps) => {
  const [eggContracts, setEggContracts] = useState<Contract[]>();
  const [ticketContract, setTicketContract] = useState<Contract>();
  const [farmContract, setFarmContract] = useState<Contract>();
  const [refContract, setRefContract] = useState<Contract>();
  const [eFameContract, setEFameContract] = useState<Contract>();
  const [erc20Contracts, setErc20Contracts] = useState<Contract[]>();
  const [erc721Contracts, setErc721Contracts] = useState<Contract[]>();

  const { library, activate, active } = useWeb3React<Web3Provider>();

  const connect = useCallback(() => {
    if (!active) {
      activate(injected);
    }
  }, [activate, active]);

  useEffect(() => {
    const connect = async () => {
      const signer = library?.getSigner();
      if (signer) {
        for (const egg of config.contracts.egg.addresses) {
          setEggContracts((prev) => [
            ...(prev ?? []),
            createContract(egg, config.contracts.egg.abi, signer)
          ]);
        }
        setTicketContract(
          createContract(
            config.contracts.ticket.address,
            config.contracts.ticket.abi,
            signer
          )
        );
        setFarmContract(
          createContract(
            config.contracts.farm.address,
            config.contracts.farm.abi,
            signer
          )
        );
        setRefContract(
          createContract(
            config.contracts.ref.address,
            config.contracts.ref.abi,
            signer
          )
        );
        setEFameContract(
          createContract(
            config.contracts.efame.address,
            config.contracts.efame.abi,
            signer
          )
        );
        for (const erc20 of config.contracts.erc20.addresses) {
          setErc20Contracts((prev) => [
            ...(prev ?? []),
            createContract(erc20, config.contracts.erc20.abi, signer)
          ]);
        }
        for (const { address: erc721 } of config.contracts.erc721.farms) {
          setErc721Contracts((prev) => [
            ...(prev ?? []),
            createContract(erc721, config.contracts.erc721.abi, signer)
          ]);
        }
      }
    };

    if (active) {
      connect();
    }
  }, [active]);

  useEffect(() => {
    connect();
  }, [connect]);

  const value: ContractContextValue = {
    eggs: eggContracts,
    ticket: ticketContract,
    farm: farmContract,
    ref: refContract,
    efame: eFameContract,
    erc20s: erc20Contracts,
    erc721s: erc721Contracts,
    connect
  };

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};

export default ContractProvider;

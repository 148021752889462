import React, { useEffect, useRef, useState } from 'react';
import InventoryItem from './InventoryItem';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import useEggTokens, { EggToken } from './useEggTokens';
import Loader from '../../components/Loader';
import InventoryFilters, { InventoryItemPredicate } from './InventoryFilters';
import { useContracts } from '../../ContractContext';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { ReadOnlyComponent } from '../../utils/types';
import InventoryAssetPreview from './InventoryAssetPreview';

interface InventoryProps extends ReadOnlyComponent {
  account?: string;
  onTokenClick?: (tokenId: number) => void;
  selectedTokenId?: number;
  onQuickPreviewClose?: () => void;
}

const Inventory = ({
  account,
  onTokenClick,
  onQuickPreviewClose,
  selectedTokenId,
  readOnly
}: InventoryProps) => {
  account = account ?? useWeb3React<Web3Provider>().account ?? undefined;
  const { balance, getToken, getTokenByIndex } = useEggTokens(account);
  const { eggs } = useContracts();
  const egg = eggs?.[0];

  const page = useRef<number>(0);

  const [tokens, setTokens] = useState<EggToken[]>();
  const [tokenBlockList, setTokenBlockList] = useState<number[]>([]);
  const [predicate, setPredicate] = useState<InventoryItemPredicate>(
    () => true
  );

  useEffect(() => {
    page.current = 0;

    setTokens(undefined);
    setTokenBlockList([]);
    setPredicate(() => () => true);
  }, [account]);

  const blockListSelectedToken = () => {
    if (selectedTokenId) {
      setTokenBlockList((list) => [...list, selectedTokenId]);
    }
  };

  const handleSomeBoolChange = async () => {
    if (!tokens || !selectedTokenId) return;

    const tokenMeta = await getToken(selectedTokenId);
    const index = tokens.findIndex((t) => t.id === selectedTokenId);
    tokens[index] = tokenMeta;
    setTokens([...tokens]);
  };

  const loadMore = async () => {
    if (balance && page.current < balance) {
      const token = await getTokenByIndex(page.current++);
      setTokens((prev) => [...(prev ?? []), token]);
    }
  };

  const handlePredicateChange = (p: InventoryItemPredicate) => {
    setPredicate(() => p);
  };

  const emptyView = () => (
    <Typography align="center">No Token found. Please buy some</Typography>
  );

  const scrollRef = useRef<HTMLDivElement>(null);

  if (!balance) return null;

  return tokens && tokens.length === 0 ? (
    emptyView()
  ) : (
    <div>
      {selectedTokenId && account && egg && (
        <InventoryAssetPreview
          token={tokens?.find((t) => t.id === selectedTokenId)}
          onClose={onQuickPreviewClose}
          readOnly={readOnly}
          eggContract={egg}
          onSomeBoolChange={handleSomeBoolChange}
          onTransfer={blockListSelectedToken}
          onRecycle={blockListSelectedToken}
        />
      )}
      <InventoryFilters onPredicateChange={handlePredicateChange} />
      <InfiniteScroll
        loadMore={loadMore}
        loader={<Loader />}
        hasMore={!tokens || tokens.length < balance}
        getScrollParent={() => scrollRef.current}
        pageStart={-1}
      >
        <Grid id="itemsContainer" container spacing={2} ref={scrollRef}>
          {tokens
            ?.filter(
              (token) =>
                (!predicate || predicate(token)) &&
                !tokenBlockList.includes(token.id)
            )
            .map((token) => (
              <Grid key={token.id} item xs={4}>
                <InventoryItem
                  {...token}
                  onClick={() => onTokenClick?.(token.id)}
                  eggContract={egg}
                />
              </Grid>
            ))}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default Inventory;

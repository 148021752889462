import React from 'react';
import { Button } from '@material-ui/core';
import config from '../../utils/config';
import LaunchIcon from '@material-ui/icons/Launch';
import ShareIcon from '@material-ui/icons/Share';
import { useSnackbar } from 'notistack';

interface InventoryItemDetailsModalButtonsProps {
  tokenId?: number;
}

const InventoryItemDetailsModalButtons = ({
  tokenId,
  children
}: React.PropsWithChildren<InventoryItemDetailsModalButtonsProps>) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleShareClick = async () => {
    await navigator.clipboard.writeText(
      `${window.location.origin}/asset/${tokenId}`
    );
    enqueueSnackbar('Copied URL to clipboard!', {
      variant: 'success'
    });
  };

  return (
    <div>
      <Button
        href={`https://opensea.io/assets/${config.contracts.egg.addresses[0]}/${tokenId}`}
        target="_blank"
        startIcon={<LaunchIcon />}
      >
        View on Opensea
      </Button>
      <Button onClick={handleShareClick} startIcon={<ShareIcon />}>
        Share
      </Button>
      {children}
    </div>
  );
};

export default InventoryItemDetailsModalButtons;

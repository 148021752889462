import React, { useCallback, useEffect, useState } from 'react';
import config from '../../../utils/config';
import { BigNumber, Contract, utils } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useContracts } from '../../../ContractContext';
import useOnBlock from '../../../utils/useOnBlock';

interface StatisticsContextValue {
  staked?: number;
  reward?: number;
  update?: () => void;
}

export const StatisticsContext = React.createContext<StatisticsContextValue>(
  {}
);

export const useStatistics = () => React.useContext(StatisticsContext);

interface ERC20StakesResponse {
  tokenStaked: BigNumber;
}

interface StatisticsContextProviderProps {
  children: React.ReactNode;
  erc20Contract: Contract;
}

const StatisticsProvider = ({
  children,
  erc20Contract
}: StatisticsContextProviderProps) => {
  const { account } = useWeb3React<Web3Provider>();
  const { farm } = useContracts();

  const [staked, setStaked] = useState<number>();
  const [reward, setReward] = useState<number>();

  const update = useCallback(async () => {
    const resp: ERC20StakesResponse = await farm?.erc20Stakes(
      account,
      erc20Contract.address
    );
    const staked = +utils.formatEther(resp?.tokenStaked ?? 0);

    let reward = 0;
    if (staked !== 0) {
      reward = await farm?.calcCurrentPayoutERC20(
        account,
        erc20Contract.address
      );
    }

    setStaked(staked);
    setReward(+utils.formatEther(reward));
  }, [farm, account]);

  useOnBlock(update);

  useEffect(() => {
    update();
  }, [update]);

  const value: StatisticsContextValue = {
    staked,
    reward,
    update
  };

  return (
    <StatisticsContext.Provider value={value}>
      {children}
    </StatisticsContext.Provider>
  );
};

export default StatisticsProvider;

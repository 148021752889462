import React from 'react';
import { BaseInventoryItemProps } from './InventoryItem';
import { Typography } from '@material-ui/core';

interface InventoryItemDetailsModalHeaderProps extends BaseInventoryItemProps {
  id: number;
  power: number;
}

const InventoryItemDetailsModalProperties = ({
  level,
  name,
  id,
  power,
  fame,
  rarity,
  mood,
  class: className
}: Omit<InventoryItemDetailsModalHeaderProps, 'imgSrc'>) => {
  return (
    <>
      <Typography>{`ID: ${id}`}</Typography>
      <Typography>{`Name: ${name}`}</Typography>
      <Typography>{`Level: ${level}`}</Typography>
      <Typography>{`Fame: ${fame}`}</Typography>
      <Typography>{`Rarity: ${rarity}`}</Typography>
      {mood && <Typography>{`Mood: ${mood}`}</Typography>}
      <Typography>{`Class: ${className}`}</Typography>
      <Typography gutterBottom>{`Power: ${power}`}</Typography>
    </>
  );
};

export default InventoryItemDetailsModalProperties;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ModalPaper from '../../components/ModalPaper';
import CenteringModal from '../../components/CenteringModal';

interface InventoryItemDetailsModalProps {
  open: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: 500,
    maxWidth: 700,
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto'
    }
  }
}));

const InventoryItemDetailsModal = ({
  children,
  onClose,
  open
}: React.PropsWithChildren<InventoryItemDetailsModalProps>) => {
  return (
    <CenteringModal open={open} onClose={onClose}>
      <ModalPaper className={useStyles().modal} onClose={onClose}>
        {children}
      </ModalPaper>
    </CenteringModal>
  );
};

export default InventoryItemDetailsModal;

import React, { useState } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

const ActionButton = ({
  children,
  disabled,
  onClick,
  workingText,
  ...rest
}: Omit<ButtonProps, 'onClick'> & {
  onClick?: () => Promise<void>;
  workingText: string;
}) => {
  const [internalDisabled, setDisabled] = useState(false);

  const handleClick = async () => {
    setDisabled(true);
    try {
      await onClick?.();
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Button
      onClick={handleClick}
      disabled={disabled || internalDisabled}
      {...rest}
    >
      {internalDisabled ? workingText : children}
    </Button>
  );
};

ActionButton.defaultProps = {
  workingText: 'Working...'
};

export default ActionButton;

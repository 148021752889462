import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useCallback } from 'react';
import { Contract } from 'ethers';
import { useContracts } from '../../../ContractContext';
import useStakedTokens from '../itemFarm/useStakedTokens';
import { EggToken } from '../../inventory/useEggTokens';

export default (t1Contract: Contract) => {
  const { account } = useWeb3React<Web3Provider>();
  const { farm } = useContracts();

  const calcCurrentPayoutFunc = useCallback(
    async (tokenId: number) => {
      return await farm?.calcCurrentPayoutT1(
        t1Contract.address,
        account,
        tokenId
      );
    },
    [farm, account]
  );

  const tokenIdsFromUserFunc = useCallback(async () => {
    return await farm?.tokenIdsFromUserT1(t1Contract.address, account);
  }, [farm, account]);

  return useStakedTokens<EggToken>(calcCurrentPayoutFunc, tokenIdsFromUserFunc);
};

import React, { useCallback } from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import BuyTokensTabPanel from '../tabs/BuyTokensTabPanel';
import ContractProvider from '../ContractContext';
import InventoryTabPanel from '../tabs/InventoryTabPanel';
import HomeTabPanel from '../tabs/HomeTabPanel';
import FarmTabPanel from '../tabs/FarmTabPanel';
import TicketProvider from '../features/buyTicket/TicketContext';
import Footer from '../components/Footer';
import { Switch, Route, BrowserRouter, Link } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import AssetPreview from '../features/assetPreview/AssetPreview';
import RefLinkRoute from '../routes/RefLinkRoute';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 640,
    minHeight: '100vh',
    margin: '0 auto'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  }
});

const tabs = [
  {
    label: 'Home',
    route: ['/', '/asset/:assetId'],
    link: '/',
    exact: true,
    component: HomeTabPanel
  },
  {
    label: 'Buy eGGs',
    route: '/buy',
    link: '/buy',
    component: BuyTokensTabPanel
  },
  {
    label: 'Inventory',
    route: '/inventory',
    link: '/inventory',
    component: InventoryTabPanel
  },
  {
    label: 'Farm',
    route: '/farm',
    link: '/farm',
    component: FarmTabPanel
  }
];

function App() {
  const getLibrary = useCallback((provider: any): Web3Provider => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  }, []);

  const classes = useStyles();

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ContractProvider>
        <TicketProvider>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <BrowserRouter>
              <Switch>
                <Route path="/ref/:refWallet" component={RefLinkRoute} />
                <Route path="/asset/:assetId">
                  <AssetPreview />
                </Route>
                <div className={classes.root}>
                  <div className={classes.main}>
                    <Route
                      render={({ location }) => (
                        <>
                          <AppBar position="static">
                            <Tabs
                              value={tabs.findIndex((t) =>
                                t.exact
                                  ? t.link === location.pathname
                                  : location.pathname.startsWith(t.link)
                              )}
                              aria-label="tabs"
                            >
                              {tabs.map(({ label, link }, i) => (
                                <Tab
                                  label={label}
                                  key={i}
                                  component={Link}
                                  to={link}
                                />
                              ))}
                            </Tabs>
                          </AppBar>
                          <Switch>
                            {tabs.map(({ component: Component, ...tab }, i) => (
                              <Route exact={tab.exact} key={i} path={tab.route}>
                                <Component />
                              </Route>
                            ))}
                          </Switch>
                        </>
                      )}
                    />
                  </div>
                  <Footer />
                </div>
              </Switch>
            </BrowserRouter>
          </SnackbarProvider>
        </TicketProvider>
      </ContractProvider>
    </Web3ReactProvider>
  );
}

export default App;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 300
  }
});

const InventoryItemDetailsModalLoading = () => {
  return (
    <div className={useStyles().loadingContainer}>
      <CircularProgress />
    </div>
  );
};

export default InventoryItemDetailsModalLoading;

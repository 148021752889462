import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useContracts } from '../../ContractContext';
import { utils } from 'ethers';
import config from '../../utils/config';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useSnackbar } from 'notistack';

const BuyEggTokens = () => {
  const { account, active } = useWeb3React<Web3Provider>();
  const { enqueueSnackbar } = useSnackbar();

  const [price, setPrice] = useState<string>();
  const { eggs, ref } = useContracts();
  const contract = eggs?.[0];

  useEffect(() => {
    contract
      ?.tokenPrice()
      .then((val: string) => setPrice(utils.formatEther(val)));
  }, [contract]);

  const getRefWallet = () => localStorage.getItem('refWallet');

  const buyToken = async () => {
    if (price) {
      const options = {
        value: utils.parseEther(price),
        gasLimit: config.gasLimits.singleBuy
      };

      const refWallet = getRefWallet();
      let tx;
      if (refWallet && utils.isAddress(refWallet)) {
        tx = await ref?.buyTokenRef(account, refWallet, options);
      } else {
        tx = await contract?.buyToken(account, options);
      }
      if (tx) {
        enqueueSnackbar('Buying token...', {
          autoHideDuration: 5000
        });
        await tx.wait();
        enqueueSnackbar('Token bought successfully!', {
          variant: 'success'
        });
      }
    }
  };

  const buy6Tokens = async () => {
    if (price) {
      const totalPrice = (5.5 * +price).toString();
      const options = {
        value: utils.parseEther(totalPrice),
        gasLimit: config.gasLimits.sixBuy
      };

      const refWallet = getRefWallet();
      let tx;
      if (refWallet && utils.isAddress(refWallet)) {
        tx = await ref?.buyTokenRef(account, refWallet, options);
      } else {
        tx = await contract?.buy6Tokens(account, options);
      }
      if (tx) {
        enqueueSnackbar('Buying 6 tokens...', {
          autoHideDuration: 5000
        });
        await tx.wait();
        enqueueSnackbar('6 tokens bought successfully!', {
          variant: 'success'
        });
      }
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <Button
          disabled={!active}
          fullWidth
          variant="contained"
          color="primary"
          onClick={buyToken}
        >
          {`Buy 1 eGG ${price ? ` (${price} BNB)` : ''}`}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={!active}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={buy6Tokens}
        >
          {`Buy 6 eGGs ${price ? ` (${+price * 5.5} BNB)` : ''}`}
        </Button>
      </Grid>
    </>
  );
};

export default BuyEggTokens;

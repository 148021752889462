import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { utils } from 'ethers';

interface RouteParams {
  refWallet?: string;
}

const RefLinkRoute = () => {
  const history = useHistory();
  const { refWallet } = useParams<RouteParams>();

  useEffect(() => {
    if (refWallet && utils.isAddress(refWallet)) {
      localStorage.setItem('refWallet', refWallet);
    }
    history.push('/');
  });

  return null;
};

export default RefLinkRoute;

import React, { useState } from 'react';
import { ModalProps, Tab } from '@material-ui/core';
import ModalPaper from '../../../components/ModalPaper';
import { makeStyles } from '@material-ui/core/styles';
import { TabContext, TabList } from '@material-ui/lab';
import Erc20FarmModalStakeTab from './Erc20FarmModalStakeTab';
import Erc20FarmModalWithdrawTab from './Erc20FarmModalWithdrawTab';
import CenteringModal from '../../../components/CenteringModal';
import { Contract } from 'ethers';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: 600
    }
  }
}));

interface Erc20FarmModalProps extends Pick<ModalProps, 'open'> {
  onClose: () => void;
  erc20Contract: Contract;
}

const Erc20FarmModal = ({
  open,
  onClose,
  erc20Contract
}: Erc20FarmModalProps) => {
  const [value, setValue] = useState('1');

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: string
  ) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <CenteringModal open={open} onClose={onClose}>
      <ModalPaper className={classes.modalPaper} onClose={onClose}>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="farm tabs">
            <Tab label="Stake" value="1" />
            <Tab label="Withdraw" value="2" />
          </TabList>
          <Erc20FarmModalStakeTab erc20={erc20Contract} value="1" />
          <Erc20FarmModalWithdrawTab erc20={erc20Contract} value="2" />
        </TabContext>
      </ModalPaper>
    </CenteringModal>
  );
};

export default Erc20FarmModal;

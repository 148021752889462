import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@material-ui/core';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import GradeIcon from '@material-ui/icons/Grade';
import ProgressIcon from '../../components/ProgressIcon';
import { makeStyles } from '@material-ui/core/styles';
import { Contract } from 'ethers';

const useStyles = makeStyles({
  iconContainer: {
    textAlign: 'center'
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

export interface BaseInventoryItemProps {
  imgSrc: string;
  level: number;
  name: string;
  description: string;
  id: number;
  fame: number;
  power: number;
  rarity: string;
  mood: string;
  class: string;
}

interface InventoryItemProps extends BaseInventoryItemProps {
  booster: boolean;
  eggContract?: Contract;
  onClick?: () => void;
}

const InventoryItem = ({
  imgSrc,
  name,
  id,
  fame,
  power,
  onClick
}: InventoryItemProps) => {
  const classes = useStyles();

  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <CardMedia component="img" height="140" image={imgSrc} />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography className={classes.title}>{name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="right">{`#${id}`}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item xs className={classes.iconContainer}>
              <ProgressIcon
                title="Fame"
                renderIcon={() => <GradeIcon />}
                value={fame}
              />
              <Typography variant="subtitle2" align="center">
                Fame
              </Typography>
            </Grid>
            <Grid item xs className={classes.iconContainer}>
              <ProgressIcon
                title="Power"
                renderIcon={() => <BatteryChargingFullIcon />}
                value={power}
              />
              <Typography variant="subtitle2" align="center">
                Power
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default InventoryItem;

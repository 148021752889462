import { BaseToken, UseTokensValue } from '../../inventory/useTokens';
import { StakedToken, UseStakedTokensValue } from './useStakedTokens';
import { useCallback, useEffect, useState } from 'react';
import { BigNumber, utils } from 'ethers';
import useOnBlock from '../../../utils/useOnBlock';

export default <T extends BaseToken>(
  useTokensValue: UseTokensValue<T>,
  useStakedTokensValue: UseStakedTokensValue<T>
) => {
  const { balance, refreshBalance, getToken, getTokenByIndex } = useTokensValue;
  const {
    indices,
    refreshStakedBalance,
    getStakedToken,
    getStakedTokenValue
  } = useStakedTokensValue;

  const [tokens, setTokens] = useState<T[]>([]);
  const [stakedTokens, setStakedTokens] = useState<StakedToken<T>[]>([]);

  const refreshStakedTokenPayouts = useCallback(async () => {
    for (let i = 0; i < stakedTokens.length; i++) {
      const t1payout = await getStakedTokenValue(stakedTokens[i].id);
      stakedTokens[i].value = utils.formatEther(t1payout);
      setStakedTokens([...stakedTokens]);
    }
  }, [stakedTokens.length]);

  const loadTokens = useCallback(async () => {
    if (balance === 0) {
      setTokens([]);
    } else {
      const tokens: T[] = [];
      for (let i = 0; i < (balance ?? 0); i++) {
        const token = await getTokenByIndex(i);
        tokens.push(token);
        setTokens([...tokens]);
      }
    }
  }, [balance]);

  const loadStakedTokens = useCallback(async () => {
    if (indices) {
      if (indices.length === 0) {
        setStakedTokens([]);
      } else {
        const newTokens: StakedToken<T>[] = [];
        for (const index of indices) {
          const stakedToken = await getStakedToken(index.toNumber(), getToken);
          newTokens.push(stakedToken);
          setStakedTokens([...newTokens]);
        }
      }
    }
  }, [indices]);

  useEffect(() => {
    loadTokens();
  }, [loadTokens]);

  useEffect(() => {
    loadStakedTokens();
  }, [loadStakedTokens]);

  useOnBlock(refreshStakedTokenPayouts);

  const refreshTokenPayout = useCallback(
    async (tokenId: number) => {
      const t1payout: BigNumber = await getStakedTokenValue(tokenId);
      const token = stakedTokens.find((t) => t.id === tokenId);
      if (token) {
        token.value = utils.formatEther(t1payout);
        setStakedTokens([...stakedTokens]);
      }
    },
    [stakedTokens]
  );

  return {
    tokens,
    stakedTokens,
    refreshTokenPayout,
    refreshBalance,
    refreshStakedBalance
  };
};

import React from 'react';
import { Typography } from '@material-ui/core';

interface FarmPanelProps {
  children: React.ReactNode;
}

const FarmPanelTitle = ({ children }: FarmPanelProps) => {
  return (
    <Typography variant="h6" component="h2">
      {children}
    </Typography>
  );
};

export default FarmPanelTitle;

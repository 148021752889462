import React, { useState } from 'react';
import Erc20FarmModal from './Erc20FarmModal';
import FarmPanelTitle from '../FarmPanelTitle';
import { Button } from '@material-ui/core';
import FarmPanel from '../FarmPanel';
import Erc20FarmStatistics from './Erc20FarmStatistics';
import StatisticsProvider from './StatisticsContext';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from 'ethers';

interface Erc20FarmPanelProps {
  erc20Contract: Contract;
  className?: string;
  title?: string;
}

const Erc20FarmPanel = ({
  erc20Contract,
  className,
  title
}: Erc20FarmPanelProps) => {
  const { active } = useWeb3React<Web3Provider>();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen((v) => !v);

  return (
    <FarmPanel className={className}>
      <StatisticsProvider erc20Contract={erc20Contract}>
        <Erc20FarmModal
          erc20Contract={erc20Contract}
          open={modalOpen}
          onClose={toggleModal}
        />
        <FarmPanelTitle>{title}</FarmPanelTitle>
        <Erc20FarmStatistics />
        <Button disabled={!active} variant="contained" onClick={toggleModal}>
          Manage
        </Button>
      </StatisticsProvider>
    </FarmPanel>
  );
};

export default Erc20FarmPanel;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useContracts } from '../../../ContractContext';
import { Contract, utils } from 'ethers/lib.esm';
import { TabPanel, TabPanelProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useStatistics } from './StatisticsContext';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2)
  },
  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  maxBtn: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}));

interface Erc20FarmModalStakeTabProps extends TabPanelProps {
  erc20: Contract;
}

const Erc20FarmModalStakeTab = ({
  erc20,
  ...rest
}: Erc20FarmModalStakeTabProps) => {
  const { account } = useWeb3React<Web3Provider>();

  const { farm } = useContracts();
  const { update: updateStatistics } = useStatistics();

  const [allowance, setAllowance] = useState<number>();
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);
  const [hasApproved, setHasApproved] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>();
  const [amount, setAmount] = useState<number>();

  const refreshBalance = useCallback(async () => {
    const balance = await erc20?.balanceOf(account);
    const allowance = await erc20?.allowance(account, farm?.address);

    setBalance(+utils.formatEther(balance));
    setAllowance(+utils.formatEther(allowance));
  }, [erc20, farm]);

  useEffect(() => {
    refreshBalance();
  }, [refreshBalance]);

  useEffect(() => {
    const approved = allowance && allowance > (amount ?? 0);
    setHasApproved(!!approved);
  }, [allowance, amount]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(+(+e.target.value).toFixed(6));
  };

  const handleMaxClick = () => {
    if (balance) {
      setAmount(+balance.toFixed(6));
    }
  };

  const handleActionButtonClick = async () => {
    setButtonEnabled(false);
    const wei = utils.parseEther((amount ?? 0).toString());

    let tx;
    if (!hasApproved) {
      const defaultApproveValue = 1000000000;
      tx = await erc20?.approve(
        farm?.address,
        utils.parseEther(
          ((amount ?? 0) > defaultApproveValue
            ? amount ?? 0
            : defaultApproveValue
          ).toString()
        )
      );

      setHasApproved(true);
      await tx.wait();
    } else {
      tx = await farm?.stakeERC20(erc20.address, wei);
      await tx.wait();

      setHasApproved(false);
      await refreshBalance();
      if (updateStatistics) {
        await updateStatistics();
      }
    }

    setButtonEnabled(true);
  };

  const classes = useStyles();

  return (
    <TabPanel {...rest}>
      <Typography
        variant="h6"
        component="h2"
        align="center"
        className={classes.header}
      >
        PanCake eFAME/BNB
      </Typography>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="subtitle2" align="right">
              {`Available: ${balance ?? 'loading...'}`}
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                onChange={handleAmountChange}
                label="Amount"
                type="number"
                value={amount ?? ''}
                disabled={!buttonEnabled}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="start">eFAME/BNB</InputAdornment>
                  )
                }}
                inputProps={{
                  step: 0.1,
                  min: 0,
                  max: balance
                }}
                variant="outlined"
                placeholder="0"
              />
            </Grid>
            <Grid item container xs={2}>
              <Button
                disabled={!buttonEnabled}
                onClick={handleMaxClick}
                className={classes.maxBtn}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Max
              </Button>
            </Grid>
          </Grid>
          <Grid item xs>
            <Button
              onClick={handleActionButtonClick}
              disabled={!buttonEnabled || !amount}
              variant="contained"
              color="primary"
              fullWidth
            >
              {buttonEnabled
                ? hasApproved
                  ? 'Stake'
                  : 'Approve'
                : 'Working...'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </TabPanel>
  );
};

export default Erc20FarmModalStakeTab;

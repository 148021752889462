import React from 'react';
import BuyTokens from '../features/buy/BuyTokens';
import { Box } from '@material-ui/core';
import AppTabPanel from './AppTabPanel';

const BuyTokensTabPanel = () => {
  return (
    <AppTabPanel>
      <BuyTokens />
    </AppTabPanel>
  );
};

export default BuyTokensTabPanel;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    color: theme.palette.primary.main,
    position: 'relative',
    margin: theme.spacing(1)
  },
  progress: {
    position: 'absolute',
    left: -8,
    top: -8
  }
}));

interface ProgressIconProps {
  renderIcon: () => React.ReactNode;
  title: string;
  value?: number;
}

const ProgressIcon = ({ renderIcon, title, value }: ProgressIconProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {renderIcon()}
      <Tooltip title={`${title}: ${value}`}>
        <CircularProgress
          className={classes.progress}
          value={value}
          variant="static"
        />
      </Tooltip>
    </div>
  );
};

export default ProgressIcon;

import React, { useState } from 'react';
import Inventory from '../features/inventory/Inventory';
import { makeStyles } from '@material-ui/core/styles';
import BuyTicket from '../features/buyTicket/BuyTicket';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import InventorySharePanel from '../features/inventory/InventorySharePanel';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AppTabPanel from './AppTabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1
  },
  buyTicketContainer: {
    justifyContent: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2)
  }
}));

const InventoryTabPanel = () => {
  const account = useWeb3React<Web3Provider>().account ?? undefined;
  const [assetId, setAssetId] = useState<number>();

  const classes = useStyles();

  const handleItemClick = (tokenId: number) => {
    setAssetId(tokenId);
  };

  const handleQuickPreviewClose = () => {
    setAssetId(undefined);
  };

  return (
    <AppTabPanel className={useStyles().root}>
      <div className={classes.buyTicketContainer}>
        <BuyTicket />
      </div>
      <Inventory
        onTokenClick={handleItemClick}
        onQuickPreviewClose={handleQuickPreviewClose}
        selectedTokenId={assetId ? +assetId : undefined}
        account={account}
        readOnly={false}
      />
    </AppTabPanel>
  );
};

export default withRouter(InventoryTabPanel);

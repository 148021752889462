import React from 'react';
import FarmPanel from '../FarmPanel';
import FarmPanelTitle from '../FarmPanelTitle';

interface FirstFarmPanelProps {
  className?: string;
  title?: string;
  children?: React.ReactNode;
}

const ItemFarmPanel = ({ className, title, children }: FirstFarmPanelProps) => {
  return (
    <FarmPanel className={className}>
      <FarmPanelTitle>{title}</FarmPanelTitle>
      {children}
    </FarmPanel>
  );
};

export default ItemFarmPanel;

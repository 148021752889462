import React, { useCallback, useEffect, useState } from 'react';
import { ModalProps } from '@material-ui/core';
import ItemFarmModalInventory from '../itemFarm/ItemFarmModalInventory';
import { EggToken } from '../../inventory/useEggTokens';
import { useContracts } from '../../../ContractContext';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from 'ethers';
import useStakedT1Tokens from './useStakedT1Tokens';
import ActionButton from '../../../components/ActionButton';
import T1FarmModalInventoryItem from './T1FarmModalInventoryItem';
import useFarmModalInventory from '../itemFarm/useFarmModalInventory';
import useEggTokens from '../../inventory/useEggTokens';
import ItemFarmModal from '../itemFarm/ItemFarmModal';

interface FirstFarmModalProps extends Pick<ModalProps, 'open'> {
  onClose: () => void;
  egg: Contract;
}

const T1FarmModal = ({ open, onClose, egg }: FirstFarmModalProps) => {
  const { account } = useWeb3React<Web3Provider>();

  const { farm } = useContracts();
  const {
    tokens,
    refreshBalance,
    stakedTokens,
    refreshTokenPayout,
    refreshStakedBalance
  } = useFarmModalInventory<EggToken>(
    useEggTokens(account ?? undefined),
    useStakedT1Tokens(egg)
  );

  const [isApproved, setIsApproved] = useState<boolean>(false);

  const refreshApprovalStatus = useCallback(async () => {
    egg
      ?.isApprovedForAll(account, farm?.address)
      .then((approval: boolean) => setIsApproved(approval));
  }, [egg, account]);

  useEffect(() => {
    refreshApprovalStatus();
  }, [refreshApprovalStatus]);

  const handleApproveClick = async () => {
    try {
      const tx = await egg?.setApprovalForAll(farm?.address, true);
      await tx.wait();
    } finally {
      await refreshApprovalStatus();
    }
  };

  const handleStakeClick = async (tokenId: number) => {
    const tx = await farm?.stakeT1(egg.address, tokenId);
    await tx.wait();

    await refreshBalance();
    await refreshStakedBalance();
  };

  const handleWithdrawClick = async (tokenId: number) => {
    const tx = await farm?.unstakeT1(egg.address, tokenId);
    await tx.wait();

    await refreshBalance();
    await refreshStakedBalance();
  };

  const handlePayoutClick = async (tokenId: number) => {
    const tx = await farm?.payoutRewardT1(egg.address, tokenId);
    await tx.wait();

    await refreshTokenPayout(tokenId);
  };

  const tokenView = (
    token: EggToken,
    action: React.ReactNode,
    extra?: string
  ) => <T1FarmModalInventoryItem {...token} extra={extra} action={action} />;

  return (
    <ItemFarmModal onClose={onClose} open={open}>
      <ItemFarmModalInventory header="Staked">
        {stakedTokens.map((token) =>
          tokenView(
            token,
            <>
              <ActionButton
                color="primary"
                onClick={() => handleWithdrawClick(token.id)}
                disabled={!isApproved}
              >
                Unstake
              </ActionButton>
              <ActionButton
                color="secondary"
                onClick={() => handlePayoutClick(token.id)}
                disabled={!isApproved}
              >
                Claim
              </ActionButton>
            </>,
            `Payout: ${token.value}`
          )
        )}
      </ItemFarmModalInventory>
      <ItemFarmModalInventory header="Available">
        {tokens.map((token) =>
          tokenView(
            token,
            <ActionButton
              color="primary"
              onClick={() => handleStakeClick(token.id)}
              disabled={!isApproved}
            >
              Stake
            </ActionButton>
          )
        )}
      </ItemFarmModalInventory>
      {!isApproved && (
        <ActionButton
          onClick={handleApproveClick}
          variant="contained"
          color="primary"
          fullWidth
        >
          Approve
        </ActionButton>
      )}
    </ItemFarmModal>
  );
};

export default T1FarmModal;

import { useCallback, useEffect, useState } from 'react';
import { useContracts } from '../../ContractContext';
import { BigNumber } from 'ethers';
import { fetchByTokenId, EggTokenMeta } from '../../api/metaApiClient';

export interface EggToken extends EggTokenMeta {
  booster: boolean;
}

export default (account?: string) => {
  const { eggs } = useContracts();
  const contract = eggs?.[0];

  const [balance, setBalance] = useState<number>();

  const getToken = useCallback(
    async (tokenId: number) => {
      const tokenMeta = await fetchByTokenId(tokenId);
      const booster: boolean = await contract?.someBool(tokenId);

      return {
        ...tokenMeta,
        booster
      };
    },
    [contract]
  );

  const getTokenByIndex = useCallback(
    async (tokenIndex: number) => {
      const id: BigNumber = await contract?.tokenOfOwnerByIndex(
        account,
        tokenIndex
      );
      return await getToken(id.toNumber());
    },
    [contract, getToken, account]
  );

  const refreshBalance = useCallback(async () => {
    const balance: BigNumber = await contract?.balanceOf(account);
    setBalance(balance?.toNumber());
  }, [contract, account]);

  useEffect(() => {
    refreshBalance();
  }, [refreshBalance]);

  return {
    getToken,
    getTokenByIndex,
    balance,
    refreshBalance
  };
};

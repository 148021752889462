import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { EggTokenMeta, fetchByTokenId } from '../../api/metaApiClient';
import InventoryItemDetailsModal from '../inventory/InventoryItemDetailsModal';
import { Grid, Typography } from '@material-ui/core';
import InventoryItemDetailsModalImage from '../inventory/InventoryItemDetailsModalImage';
import InventoryItemDetailsModalButtons from '../inventory/InventoryItemDetailsModalButtons';
import InventoryItemDetailsModalProperties from '../inventory/InventoryItemDetailsModalProperties';
import InventoryItemDetailsModalLoading from '../inventory/InventoryItemDetailsModalLoading';

interface RouteParams {
  assetId?: string;
}

const AssetPreview = ({ history }: RouteComponentProps) => {
  const { assetId } = useParams<RouteParams>();

  const [token, setToken] = useState<EggTokenMeta>();

  useEffect(() => {
    if (assetId) {
      fetchByTokenId(+assetId).then((token) => setToken(token));
    }
  }, [assetId]);

  const handleClose = () => {
    history.push('/');
  };

  return !token ? (
    <InventoryItemDetailsModalLoading />
  ) : (
    <InventoryItemDetailsModal open={true} onClose={handleClose}>
      <Grid container spacing={2}>
        <InventoryItemDetailsModalImage imgSrc={token?.imgSrc} />
        <Grid item xs={12} sm={8}>
          <InventoryItemDetailsModalProperties {...token} />
          <InventoryItemDetailsModalButtons tokenId={token?.id} />
          <Typography variant="subtitle2">{token.description}</Typography>
        </Grid>
      </Grid>
    </InventoryItemDetailsModal>
  );
};

export default withRouter(AssetPreview);

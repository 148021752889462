import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { mdiContentCopy } from '@mdi/js';
import { makeStyles } from '@material-ui/core/styles';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden'
  },
  box: {
    display: 'flex'
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
    padding: theme.spacing(2)
  },
  icon: {
    color: theme.palette.background.paper
  },
  container: {
    display: 'flex',
    width: '100%'
  },
  walletContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2)
  },
  buttonContainer: {
    marginRight: -12,
    marginTop: -12,
    marginBottom: -12
  }
}));

interface WalletPreviewProps {
  wallet?: string | null;
}

const WalletPreview = ({ wallet }: WalletPreviewProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { account } = useWeb3React<Web3Provider>();

  const handleClick = async () => {
    await navigator.clipboard.writeText(
      `${window.location.origin}/ref/${account}`
    );

    enqueueSnackbar('Copied to clipboard', {
      variant: 'success'
    });
  };

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box className={classes.box} p={2}>
        <div className={classes.iconContainer}>
          <AccountBalanceWalletIcon
            className={classes.icon}
            aria-label="connected wallet"
          />
        </div>
        <div className={classes.container}>
          <div className={classes.walletContainer}>
            <Typography align="center">{wallet}</Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Tooltip title="Copy your Referral Link to clipboard">
              <IconButton onClick={handleClick} aria-label="copy to clipboard">
                <SvgIcon>
                  <path d={mdiContentCopy} />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default WalletPreview;

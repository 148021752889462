import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';

interface FirstFarmModalInventoryItemProps {
  imgSrc: string;
  primaryText?: string;
  secondaryText?: string;
  action?: React.ReactNode;
  extra?: string;
}

const ItemFarmModalInventoryItem = ({
  imgSrc,
  primaryText,
  secondaryText,
  extra,
  action
}: FirstFarmModalInventoryItemProps) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar src={imgSrc} />
      </ListItemAvatar>
      <ListItemText
        primary={primaryText}
        secondaryTypographyProps={{
          variant: 'caption'
        }}
        secondary={
          <>
            {secondaryText}
            <br />
            {extra && (
              <Typography
                component="span"
                color="textSecondary"
                variant="caption"
              >
                {extra}
              </Typography>
            )}
          </>
        }
      />
      <ListItemSecondaryAction>{action}</ListItemSecondaryAction>
    </ListItem>
  );
};

export default ItemFarmModalInventoryItem;

import React, { useCallback, useEffect, useState } from 'react';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTicket } from '../buyTicket/TicketContext';
import { Contract } from 'ethers';
import { ReadOnlyComponent } from '../../utils/types';

interface InventoryItemDetailsModalBoosterSwitchProps
  extends ReadOnlyComponent {
  tokenId: number;
  onSomeBoolChange?: () => void;
  eggContract: Contract;
}

const InventoryItemDetailsModalBoosterSwitch = ({
  tokenId,
  onSomeBoolChange,
  eggContract,
  readOnly
}: InventoryItemDetailsModalBoosterSwitchProps) => {
  const {
    balance: ticketBalance,
    setApprovalIfRequired,
    reloadBalance
  } = useTicket();

  const [someBool, setSomeBool] = useState<boolean | undefined>();
  const [switchEnabled, setSwitchEnabled] = useState<boolean>(true);

  const refreshSomeBoolState = useCallback(async () => {
    const val: boolean = await eggContract.someBool(tokenId);
    setSomeBool(val);
  }, [eggContract, tokenId]);

  useEffect(() => {
    refreshSomeBoolState();
  }, [refreshSomeBoolState]);

  const handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setSwitchEnabled(false);

    await setApprovalIfRequired?.();

    const tx = await eggContract?.setSomeBool(tokenId, value);
    await tx.wait();

    reloadBalance?.();

    setTimeout(() => {
      setSwitchEnabled(true);
      refreshSomeBoolState();
      onSomeBoolChange?.();
    }, 30000);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Tooltip
            title="This Staking Booster improve your Staking reward. 1 Ticket needed"
            arrow
          >
            <Switch
              disabled={readOnly || !switchEnabled || ticketBalance === 0}
              checked={!!someBool}
              onChange={handleSwitchChange}
            />
          </Tooltip>
        }
        label={switchEnabled ? 'Staking Booster' : 'Working...'}
      />
      {ticketBalance === 0 && (
        <Typography variant="subtitle2">Out of tickets.</Typography>
      )}
    </FormGroup>
  );
};

export default InventoryItemDetailsModalBoosterSwitch;

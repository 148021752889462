import React from 'react';
import { useTicket } from './TicketContext';
import { Button, Grid, Typography } from '@material-ui/core';
import ticket from '../../img/ticket.png';
import { makeStyles } from '@material-ui/core/styles';
import { utils } from 'ethers';

const useStyles = makeStyles((theme) => ({
  ticketDiv: {
    margin: theme.spacing(1),
    textAlign: 'center'
  },
  img: {
    verticalAlign: 'middle',
    maxWidth: 70,
    marginRight: theme.spacing(2),
    transform: 'rotate(15deg)'
  }
}));

const BuyTicket = () => {
  const { price, buy, balance, buy10 } = useTicket();

  let priceText = '-';
  let secondPriceText = '-';
  if (price) {
    priceText = utils.formatEther(price);
    secondPriceText = utils.formatEther(price.mul(10));
  }

  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} className={classes.ticketDiv}>
          <img className={classes.img} src={ticket} alt="ticket balance" />
          <Typography component="span">{`x ${balance ?? '-'}`}</Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={price === undefined}
            onClick={buy}
            variant="contained"
            color="primary"
          >
            {`Buy (${priceText} BNB)`}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={price === undefined}
            onClick={buy10}
            variant="contained"
            color="primary"
          >
            {`Buy 10 (${secondPriceText} BNB)`}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default BuyTicket;

import React from 'react';
import { List, ListSubheader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 200,
    overflowY: 'scroll'
  },
  header: {
    backgroundColor: theme.palette.background.paper
  }
}));

interface FirstFarmModalInventoryProps {
  children: React.ReactNode;
  header: string;
}

const ItemFarmModalInventory = ({
  children,
  header
}: FirstFarmModalInventoryProps) => {
  const classes = useStyles();

  return (
    <List
      dense
      className={classes.root}
      subheader={
        <ListSubheader className={classes.header}>{header}</ListSubheader>
      }
    >
      {children}
    </List>
  );
};

export default ItemFarmModalInventory;

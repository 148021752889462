import React from 'react';
import { Grow, Modal, ModalProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  }
});

const CenteringModal = ({ children, className, open, ...rest }: ModalProps) => {
  const classes = useStyles();

  return (
    <Modal className={clsx(className, classes.root)} open={open} {...rest}>
      <Grow in={open}>{children}</Grow>
    </Modal>
  );
};

export default CenteringModal;

import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContracts } from '../../ContractContext';
import egg from '../../img/egg.png';
import BuyEggTokens from './BuyEggTokens';
import BuyEFameTokens from './BuyEFameTokens';
import WalletPreview from './WalletPreview';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(2)
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  imgContainer: {
    textAlign: 'center'
  },
  img: {
    marginTop: theme.spacing(2),
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const BuyTokens = () => {
  const { account, active } = useWeb3React<Web3Provider>();

  const { connect } = useContracts();

  const classes = useStyles();

  return (
    <div>
      {active ? (
        <WalletPreview wallet={account} />
      ) : (
        <Button fullWidth variant="contained" color="primary" onClick={connect}>
          Connect wallet
        </Button>
      )}

      <div className={classes.imgContainer}>
        <img className={classes.img} src={egg} alt="" />
      </div>
      <Grid container spacing={2} className={classes.grid}>
        <BuyEggTokens />
      </Grid>
    </div>
  );
};

export default BuyTokens;

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { EggToken } from './useEggTokens';

export type InventoryItemPredicate = (token: EggToken) => boolean;

interface InventoryFiltersProps {
  onPredicateChange?: (predicate: InventoryItemPredicate) => void;
}

const InventoryFilters = ({ onPredicateChange }: InventoryFiltersProps) => {
  const [booster, setBooster] = useState(false);
  const [maxFame, setMaxFame] = useState<number>();
  const [minFame, setMinFame] = useState<number>();

  useEffect(() => {
    onPredicateChange?.(
      (t: EggToken) =>
        (!booster || t.booster) &&
        (!maxFame || t.fame <= maxFame) &&
        (!minFame || t.fame >= minFame)
    );
  }, [booster, maxFame, minFame]);

  const filters = [
    {
      name: 'Booster On',
      toggle: () => setBooster((p) => !p),
      isOn: booster
    },
    {
      name: 'iClops',
      toggle: () => {
        if (minFame) {
          setMinFame(undefined);
        } else {
          setMinFame(80);
          setMaxFame(undefined);
        }
      },
      isOn: !!minFame
    },
    {
      name: 'Gimmick',
      toggle: () => {
        if (maxFame) {
          setMaxFame(undefined);
        } else {
          setMaxFame(80);
          setMinFame(undefined);
        }
      },
      isOn: !!maxFame
    }
  ];

  return (
    <div style={{ marginBottom: 8 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {filters.map(({ name, toggle, isOn }, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <Switch onChange={() => toggle()} checked={isOn} />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default InventoryFilters;

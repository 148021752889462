import config from '../utils/config';

export interface Attribute<T extends string, V> {
  trait_type: T;
  value: V;
  display_type: string;
}

export interface EggTokenMeta {
  id: number;
  description: string;
  imgSrc: string;
  name: string;
  level: number;
  class: string;
  rarity: string;
  mood: string;
  power: number;
  fame: number;
}

interface TokenMetaResponse {
  id: number;
  description: string;
  image: string;
  name: string;
  attributes: [
    Attribute<'level', number>,
    Attribute<'Class', string>,
    Attribute<'Rarity', string>,
    Attribute<'Mood', string>,
    Attribute<'Power', number>,
    Attribute<'$Fame', number>
  ];
}

export const fetchByTokenId = async (
  tokenId: number
): Promise<EggTokenMeta> => {
  const response = await fetch(`${config.metaServer}/meta/${tokenId}`);
  const obj: TokenMetaResponse = {
    ...(await response.json()),
    id: tokenId
  };

  return {
    ...obj,
    imgSrc: obj.image,
    power: findAttribute(obj, 'Power'),
    level: findAttribute(obj, 'level'),
    class: findAttribute(obj, 'Class'),
    rarity: findAttribute(obj, 'Rarity'),
    mood: findAttribute(obj, 'Mood'),
    fame: findAttribute(obj, '$Fame')
  };
};

const findAttribute = <
  Attribute extends TokenMetaResponse['attributes'][number],
  Trait extends Attribute['trait_type'],
  Type extends Attribute['value']
>(
  resp: TokenMetaResponse,
  name: Trait
): Type => {
  return resp.attributes.find((a) => a.trait_type === name)?.value as Type;
};

import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { useContracts } from '../../ContractContext';
import { BigNumber, Contract } from 'ethers';
import { useSnackbar } from 'notistack';
import useTokenApproval from '../farm/useTokenApproval';
import { utils } from 'ethers/lib.esm';

interface AlertDialogProps {
  open: boolean;
  eggContract?: Contract;
  tokenId: number;
  onClose?: () => void;
  onRecycle?: () => void;
}

const InventoryItemRecycleDialog = ({
  open,
  eggContract,
  tokenId,
  onClose,
  onRecycle
}: AlertDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { ref } = useContracts();
  const { isApproved, setApprovalIfRequired } = useTokenApproval(
    ref?.address,
    eggContract
  );

  const [reward, setReward] = useState<number>();

  useEffect(() => {
    (async () => {
      const repay: number = +utils.formatEther(await ref?.repay());
      setReward(repay);
    })();
  }, [ref]);

  const handleRecycleClick = async () => {
    onClose?.();
    enqueueSnackbar(`Recycling token #${tokenId}...`, {
      autoHideDuration: 5000
    });

    try {
      await setApprovalIfRequired();

      const tx = await ref?.shareNFTeGG(tokenId);
      onRecycle?.();

      await tx.wait();

      enqueueSnackbar(`Token #${tokenId} successfully recycled!`, {
        variant: 'success'
      });
    } catch {
      enqueueSnackbar(`Couldn't recycle token #${tokenId}`, {
        variant: 'error'
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="recycle-dialog-title"
    >
      <DialogTitle id="recycle-dialog-title">Recycle this eGG?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`As a bounty you will receive ${reward ?? ''} eFAME`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isApproved === undefined}
          onClick={handleRecycleClick}
          color="primary"
        >
          {isApproved === false ? 'Approve & Recycle' : 'Recycle'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InventoryItemRecycleDialog;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useContracts } from '../../ContractContext';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber, utils } from 'ethers';
import config from '../../utils/config';
import useTokenApproval from '../farm/useTokenApproval';

interface TicketContextValue {
  buy?: () => Promise<void>;
  buy10?: () => Promise<void>;
  setApprovalIfRequired?: () => Promise<void>;
  reloadBalance?: () => void;
  balance?: number;
  price?: BigNumber;
}

export const TicketContext = React.createContext<TicketContextValue>({});

export const useTicket = () => useContext(TicketContext);

interface TicketContextProviderProps {
  children: React.ReactNode;
}

const TicketContextProvider = ({ children }: TicketContextProviderProps) => {
  const { account } = useWeb3React<Web3Provider>();

  const [ticketCount, setTicketCount] = useState<number>();
  const [ticketPrice, setTicketPrice] = useState<BigNumber>();

  const { ticket: contract } = useContracts();
  const { setApprovalIfRequired } = useTokenApproval(
    config.contracts.egg.addresses[0],
    contract
  );

  const reloadBalance = useCallback(() => {
    contract
      ?.balanceOf(account)
      .then((val: BigNumber) => setTicketCount(val.toNumber()));
  }, [contract, account]);

  useEffect(() => {
    reloadBalance();

    contract
      ?.tokenPrice()
      .then((val: BigNumber) => setTicketPrice(BigNumber.from(val)));
  }, [contract]);

  const buyTicket = useCallback(async () => {
    if (ticketPrice) {
      const tx = await contract?.buyToken(account, {
        value: ticketPrice
      });
      await tx.wait();

      reloadBalance();
    }
  }, [ticketPrice]);

  const buy10Tickets = useCallback(async () => {
    if (ticketPrice) {
      const tx = await contract?.buyTokenX(10, account, {
        value: ticketPrice.mul(10)
      });
      await tx.wait();

      reloadBalance();
    }
  }, [ticketPrice]);

  const value: TicketContextValue = {
    buy: buyTicket,
    buy10: buy10Tickets,
    setApprovalIfRequired,
    reloadBalance,
    balance: ticketCount,
    price: ticketPrice
  };

  return (
    <TicketContext.Provider value={value}>{children}</TicketContext.Provider>
  );
};

export default TicketContextProvider;

import { BigNumber, utils } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { BaseToken } from '../../inventory/useTokens';

type CalcCurrentPayoutFunc = (tokenId: number) => Promise<BigNumber>;
type TokenIdsFromUserFunc = () => Promise<BigNumber[]>;

export type StakedToken<T> = T & { value: string };

export interface UseStakedTokensValue<T extends BaseToken> {
  indices?: BigNumber[];
  getStakedToken: (
    tokenId: number,
    getToken: (tokenId: number) => Promise<T>
  ) => Promise<StakedToken<T>>;
  getStakedTokenValue: (tokenId: number) => Promise<BigNumber>;
  refreshStakedBalance: () => Promise<void>;
}

const useStakedTokens = <T extends BaseToken>(
  payoutFunc: CalcCurrentPayoutFunc,
  getTokenIds: TokenIdsFromUserFunc
): UseStakedTokensValue<T> => {
  const [indices, setIndices] = useState<BigNumber[]>();

  const refreshStakedBalance = useCallback(async () => {
    const balance = await getTokenIds();
    // the first index is known to be a 0
    if (balance) {
      setIndices(balance.slice(1));
    }
  }, []);

  useEffect(() => {
    refreshStakedBalance();
  }, [refreshStakedBalance]);

  const getStakedTokenValue = useCallback(async (tokenId: number): Promise<
    BigNumber
  > => {
    return await payoutFunc(tokenId);
  }, []);

  const getStakedToken = useCallback(
    async (
      tokenId: number,
      getToken: (tokenId: number) => Promise<T>
    ): Promise<StakedToken<T>> => {
      const token = await getToken(tokenId);
      const t1payout = await getStakedTokenValue(tokenId);
      return {
        ...token,
        value: utils.formatEther(t1payout)
      };
    },
    [getStakedTokenValue]
  );

  return { indices, getStakedToken, getStakedTokenValue, refreshStakedBalance };
};

export default useStakedTokens;

import React, { useEffect, useState } from 'react';
import { EggToken } from './useEggTokens';
import InventoryItemDetailsModal from './InventoryItemDetailsModal';
import InventoryItemDetailsModalImage from './InventoryItemDetailsModalImage';
import { Button, Grid, SvgIcon, Typography } from '@material-ui/core';
import InventoryItemDetailsModalProperties from './InventoryItemDetailsModalProperties';
import InventoryItemDetailsModalBoosterSwitch from './InventoryItemDetailsModalBoosterSwitch';
import { Contract } from 'ethers';
import { ReadOnlyComponent } from '../../utils/types';
import InventoryItemDetailsModalTransfer from './InventoryItemDetailsModalTransfer';
import InventoryItemDetailsModalButtons from './InventoryItemDetailsModalButtons';
import InventoryItemDetailsModalLoading from './InventoryItemDetailsModalLoading';
import { mdiRecycle } from '@mdi/js';
import InventoryItemRecycleDialog from './InventoryItemRecycleDialog';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

interface InventoryAssetQuickPreviewProps extends ReadOnlyComponent {
  token?: EggToken;
  eggContract: Contract;
  onRecycle?: () => void;
  onTransfer?: () => void;
  onSomeBoolChange?: () => void;
  onClose?: () => void;
}

const InventoryAssetPreview = ({
  token,
  eggContract,
  onRecycle,
  onTransfer,
  onSomeBoolChange,
  onClose,
  readOnly
}: InventoryAssetQuickPreviewProps) => {
  const { account } = useWeb3React<Web3Provider>();

  const [recycleModalOpen, setRecycleModalOpen] = useState(false);
  const [isOwned, setIsOwned] = useState<boolean>();

  useEffect(() => {
    eggContract
      ?.ownerOf(token?.id)
      .then((owner: string) => setIsOwned(owner === account));
  }, [eggContract, token]);

  const handleTransfer = () => {
    onTransfer?.();
    onClose?.();
  };

  const handleRecycle = () => {
    onRecycle?.();
    onClose?.();
  };

  const toggleRecycleModal = () => {
    setRecycleModalOpen((v) => !v);
  };

  return (
    <InventoryItemDetailsModal open={true} onClose={onClose}>
      {!token ? (
        <InventoryItemDetailsModalLoading />
      ) : (
        <>
          <InventoryItemRecycleDialog
            eggContract={eggContract}
            tokenId={token.id}
            open={recycleModalOpen}
            onRecycle={handleRecycle}
            onClose={toggleRecycleModal}
          />
          <Grid container spacing={2}>
            <InventoryItemDetailsModalImage imgSrc={token.imgSrc} />
            <Grid item xs={12} sm={8}>
              <InventoryItemDetailsModalProperties {...token} />
              <InventoryItemDetailsModalButtons tokenId={token.id}>
                {!readOnly && (
                  <Button
                    disabled={!isOwned}
                    startIcon={
                      <SvgIcon>
                        <path d={mdiRecycle} />
                      </SvgIcon>
                    }
                    onClick={toggleRecycleModal}
                  >
                    Recycle
                  </Button>
                )}
              </InventoryItemDetailsModalButtons>
              <InventoryItemDetailsModalBoosterSwitch
                tokenId={token.id}
                eggContract={eggContract}
                readOnly={readOnly || !isOwned}
                onSomeBoolChange={onSomeBoolChange}
              />
              <Typography variant="subtitle2">{token.description}</Typography>
            </Grid>
          </Grid>
          {!readOnly && (
            <InventoryItemDetailsModalTransfer
              id={token.id}
              onTransfer={handleTransfer}
              eggContract={eggContract}
            />
          )}
        </>
      )}
    </InventoryItemDetailsModal>
  );
};

export default InventoryAssetPreview;
